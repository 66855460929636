
body {
  margin: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url(/public/images/bg.png) no-repeat center center fixed;
  background-size: cover;
  font-family: "Source Code Pro", "SF Mono", Monaco, Inconsolata, "Fira Mono",
      "Droid Sans Mono", monospace, monospace;
  white-space: pre;
  display: block;
  padding: 1em;
  margin-bottom: 1em;
  border-bottom: 2px solid black;
  content: "Error: expected \"{\".\a   \2577 \a 1 \2502  import {'~bootstrap/dist/css/bootstrap.min.css'}\a   \2502                                                 ^\a   \2575 \a   index.scss 1:48  root stylesheet";
}
